<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">精品课程推荐</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="RelatedCourses()"
                >关联课程</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableCourseData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="课程名称"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="150"
              />

              <el-table-column
                label="课节"
                align="center"
                prop="coursewareNum"
                show-overflow-tooltip
                min-width="150"
              />

              <el-table-column label="关联时间" align="center" width="120">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.createTime | momentDate }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="260px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="() => movePosition(scope.row, scope.$index)"
                    >移动位置</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="() => remove(scope.row.recommendId,scope.$index)"
                    >移除</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        /> -->
      </div>
    </div>
    <!-- 查看分享吗的弹框 -->
    <el-dialog title="移动位置" :visible.sync="movePositionDialog">
      <div>
        <p>
          将课程<span style="font-weight: bold; color: red">{{
            courseInfo.courseName
          }}</span
          >移动到:
        </p>
        <el-radio-group v-model="moveLast">
          <el-radio :label="false"
            >课程
            <el-input-number
              v-model="moveNum"
              @change="handleChange"
              :min="1"
              size="small"
            ></el-input-number
            >前</el-radio
          >
          <el-radio :label="true">移为最后一项</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="doMoveClose">取消</el-button>
        <el-button class="bgc-bv" @click="doOkMove">确定</el-button>
      </span>
    </el-dialog>
    <courseTableMore ref="courseTableMore" @eventBus="courseTableMoreBack" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import courseTableMore from "@/views/miniApp/excellentCourses/courseTable";
export default {
  name: "teacherList",
  components: {
    Empty,
    courseTableMore,
  },
  mixins: [List],
  data() {
    return {
      // 移动 - 状态
      movePositionDialog: false,
      courseName: "",
      moveNum: "1",
      moveLast: false,
      tableCourseData: [],
      courseInfo: {},
      index: "",
    };
  },
  computed: {},
  created() {
    this.getListData();
    this.getTableHeight();
  },
  methods: {
    getListData() {
      this.$post("/minicourse/home/recommend/list", {}, 3000, true, 2).then(
        (res) => {
          this.tableCourseData = res.data || [];
        }
      );
    },
    //关联课程
    RelatedCourses() {
      this.$refs.courseTableMore.showPopUp();
    },
    //批量选择回调
    courseTableMoreBack(ret) {
      console.log(ret);
      let courseIds = [];
      let oldCourseIds = [];
      if (this.tableCourseData.length > 0) {
        this.tableCourseData.map((el1) => {
          oldCourseIds.push(el1.courseId);
        });
        console.log("55",oldCourseIds);
        ret.map((el) => {
          if (oldCourseIds.indexOf(el.courseId) === -1) {
            courseIds.push(el.courseId);
          }
        });
      } else {
        console.log("66");
        ret.map((el) => {
          courseIds.push(el.courseId);
        });
      }

      this.$post(
        "/minicourse/home/recommend/insert",
        { courseIds },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == 0) {
          this.$message.success("关联成功");
          this.getListData();
        }
      });
    },
    movePosition(row, index) {
      this.courseInfo = row;
      this.index = index;
      this.movePositionDialog = true;
    },
    //移动确认
    doOkMove() {
      let parameter = {
        recommendId: this.courseInfo.recommendId,
        currentIndex: this.index,
      };
      if (!this.moveLast) {
        parameter.moveIndex = this.moveNum;
        parameter.moveLast = this.moveLast;
      } else {
        parameter.moveLast = this.moveLast;
      }
      this.$post(
        "/minicourse/home/recommend/weightUpdate",
        parameter,
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == 0) {
          this.$message.success("移动成功");
          this.doMoveClose();
          this.getListData();
        }
      });
    },
    //移动取消
    doMoveClose() {
      this.movePositionDialog = false;
      this.moveLast = false;
      this.moveNum = "1";
      this.courseInfo = {};
      this.index = "";
    },
    remove(recommendId,deleteIndex) {
      this.$confirm("确定删除关联课程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$post(
            "/minicourse/home/recommend/delete",
            {
              recommendId,
              deleteIndex
            },
            3000,
            true,
            2
          ).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-radio-group {
  display: flex;
  flex-direction: column;
  .el-radio {
    padding: 10px;
  }
}
</style>
